<template>
	<v-dialog
		v-model="show_bid_box"
		height="auto"
		width="1280"
		transition="slide-fade"
		hide-overlay
		light
	>
		<v-row no-gutters dense>
			<v-card min-height="100%" min-width="100%" class="my-card d-flex">
				<v-row no-gutters dense class="d-flex flex-column">
					<v-row no-gutters dense class="d-flex">
						<v-col cols="12">
							<p class="heading-sf20 pb-4">List</p>
						</v-col>

						<v-col cols="12" class="list-header pb-4">
							<v-row no-gutters dense>
								<v-col cols="6" class="d-flex align-center">
									<img
										v-if="list_info.category_id === 1"
										:src="$store.state.icons.icons['Lumber']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="list_info.category_id === 2"
										:src="$store.state.icons.icons['Electric']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="list_info.category_id === 3"
										:src="$store.state.icons.icons['Plumbing']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="list_info.category_id === 4"
										:src="$store.state.icons.icons['Masonry']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="list_info.category_id === 5"
										:src="$store.state.icons.icons['ToolBoxes']"
										style="height:36px;"
										alt
									/>

									<div class="pl-7">
										<p class="heading-mf15">{{ list_info.list_name }}</p>
										<p class="heading-rf13">List ref: {{ list_info.list_id }}</p>
									</div>
								</v-col>
								<v-col cols="6" class="d-flex align-center justify-end">
									<app-list-hours v-model="list_info.idle_time" v-if="list_info.status === 'A'"></app-list-hours>
									<div
										v-else-if="list_info.status === 'E'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>List Expired</div>
									<div
										v-else-if="list_info.status === 'BA'"
										class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2"
									>Bid Accepted</div>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="pt-6">
							<v-row no-gutters dense>
								<v-col cols="6" class="heading-sf16 pb-2">List Items</v-col>
								<v-col cols="6" class="heading-sf16 pl-4 pb-2">List Information</v-col>
								<v-col cols="6" class="pr-4 pb-4 list-item-view">
									<v-simple-table class="bid-item-table">
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">Name</th>
													<th class="text-left">Qty</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in list_info.list_items" :key="index">
													<td>{{ item.name }}</td>
													<td>{{ item.qty }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-col>
								<v-col cols="6" class="pl-4 pt-2 pb-4">
									<div class="d-flex pb-2">
										<div class="list-detail-title">Address:</div>
										<div class="heading-rf15">{{ list_info.address }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Created at:</div>
										<div class="heading-rf15">{{ list_info.created_at }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Required delivery date:</div>
										<div class="heading-rf15">{{ list_info.delivery_date }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Required delivery time:</div>
										<div class="heading-rf15">{{ requiredDeliveryTime() }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Pickup:</div>
										<div class="heading-rf15">{{ list_info.pickup === 'Y' ? 'Yes' : 'No' }}</div>
									</div>
									<div class="d-flex pb-2" v-if="list_info.pickup === 'Y'">
										<div class="list-detail-title">Pickup range:</div>
										<div class="heading-rf15">{{ list_info.pickup_range }}</div>
									</div>
									<div class="d-flex pb-2" v-if="list_info.notes !== null">
										<div class="list-detail-title">Notes:</div>
										<div class="heading-rf15">{{ list_info.notes }}</div>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<v-row no-gutters dense align="stretch" class="d-flex">
						<v-col cols="12" class="d-flex justify-end mt-auto pt-4">
							<v-btn class="my-outline-btn" id="my_elevation" @click="show_bid_box = false" depressed>Close</v-btn>
						</v-col>
					</v-row>
				</v-row>
			</v-card>
		</v-row>
	</v-dialog>
</template>

<script>
import { listDetails } from "../../services/listservice";
import { dateFormat, timeFormat, localTimeFromDateTime } from '../../services/commonservice';
export default {
	data() {
		return {
			show_bid_box: false,
			list_info: {
				list_items: []
			}
		};
	},

	methods: {
		async openListView(id) {
			let data = await listDetails(id)
			this.list_info.list_id =  data.data.list_id;
			this.list_info.category_id = data.data.category_id;
			this.list_info.list_name = data.data.list_name; 
			this.list_info.address = data.data.address;
			this.list_info.status = data.data.status;
			this.list_info.city = data.data.city;
			this.list_info.state = data.data.state;
			this.list_info.country = data.data.country;
			this.list_info.pin_code = data.data.pin_code;
			this.list_info.delivery_date = dateFormat(data.data.delivery_date);
			this.list_info.created_at = localTimeFromDateTime(data.data.created_at);
			this.list_info.idle_time = data.data.idle_time;
			this.list_info.pickup = data.data.pickup;
			this.list_info.pickup_range = data.data.pickup_range;
			this.list_info.delivery_time = data.data.delivery_time;
			this.list_info.notes = data.data.notes;
			this.list_info.list_items = [];
			data.data.listitems.forEach(item => {
				let ob = {
					name: item.name,
					qty: item.qty
				}

				this.list_info.list_items.push(ob);
			});
			this.show_bid_box = true;
		},
		requiredDeliveryTime() {
			let time = this.list_info.delivery_time;

			if (time === "M") {
				return "Morning";
			} else if (time === "N") {
				return "Noon";
			} else if (time === "A") {
				return "Afternoon";
			} else if (time === "L") {
				return "Last Stop";
			} else if (time === "AS") {
				return "As soon as possible";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.list-header {
	border-bottom: 1px solid #dde2ee;
}

.list-item-view {
	border-right: 1px solid #dde2ee;
}

.list-detail-title {
	font-family: Inter-Regular;
	font-size: 15px;
	color: #7687a2;
	width: 200px;
}
</style>